import React from "react"
import tw, { styled } from "twin.macro"
import {Link} from 'gatsby'
import Form from "../components/Form"
import Layout from '../components/Layout'

const Title = tw.h1`mb-6 text-lg`


export default function Home({navigate}) {
const navigateAfterSubmission = (email) => navigate('/message/', {state: {email}})
  return (
    <Layout>
        <Title tw="text-white font-bold">أكتب رسالة لنفسك في المستقبل</Title>
        <Form onSubmit={navigateAfterSubmission}/>
    </Layout>
  )
}
