import React from "react"
import tw, { styled } from "twin.macro"
import { FormComponent } from "./style"

const Title = styled.span`
  background: -webkit-linear-gradient(-45deg, #8363a1 0%, #74a8c3 100%);
  background: linear-gradient(135deg, #8363a1 0%, #74a8c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Button = styled.button`
  vertical-align: middle;
  border: 4px solid transparent;
  border-image: linear-gradient(to right, #8363a1 0%, #74a8c3 100%);
  border-image-slice: 1;
  background: #fff;
  color: #ffffff;
  font-size: 23px;
  height: 60px;
  line-height: 50px;
  font-weight: bold;
  vertical-align: center;
  /* margin-top: 2rem; */
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 30px;
  font-family: inherit;
  width: 90%;
  &:hover {
    background-color: white;
    background: linear-gradient(to right, #8363a1 0%, #74a8c3 100%);
    color: #fff;
    cursor: pointer;
  }

  &:hover ${Title} {
    background: #fff;
    -webkit-background-clip: text;
  }

  ${tw`w-full`}

  ${FormComponent}:invalid &:hover {
    cursor: not-allowed;
    background-color: red;
  }
`
const SubmitButton = ({ onSubmit }) => (
  <>
    <Button type="submit" onClick={onSubmit}>
      {" "}
      <Title dir="rtl">أرسل إلى المستقبل!</Title>
    </Button>
  </>
)

export default SubmitButton
