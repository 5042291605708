import React, { useState } from "react"
import tw from "twin.macro"
import SubmitButton from "./SubmitButton"
import {Box, FormComponent} from './style'
import {addMessage} from '../api'


const Form = ({onSubmit}) => {
  const [message, setMessage] = useState(`عزيزي انا المستقبلي.. `)
  const [sendAfter, setSendAfter] = useState("1y")
  const [email, setEmail] = useState("")

  const onFormSubmit = async (event) => {
    event.preventDefault()
    // console.log({ message, sendAfter, email })
    addMessage({text: message, email, sendAfter})
    onSubmit(email);
  }

  return (
    <>
      <Box>
        <FormComponent onSubmit={onFormSubmit}>
          <label htmlFor="message" tw="block w-full pb-5">
            <span tw="text-gray-700">رسالتك المستقلبية</span>
            <textarea
              id="message"
              tw="form-textarea mt-1 block w-full"
              rows="5"
              placeholder=""
              value={message}
              onChange={event => setMessage(event.target.value)}
            />
          </label>

          <label htmlFor="sendAfter" tw="block w-full pb-5">
            <span tw="text-gray-700">أرسلها بعد</span>
            <select
              id="sendAfter"
              tw="form-select block w-full mt-1"
              value={sendAfter}
              onChange={event => setSendAfter(event.target.value)}
            >
              <option value="2w">أسبوعين</option>
              <option value="1m">شهر واحد</option>
              <option value="3m">ثلاث شهور</option>
              <option value="6m">ست شهور</option>
              <option value="1y">سنة واحدة</option>
              <option value="3y">ثلاث سنوات</option>
              <option value="5y">خمس سنوات</option>
            </select>
          </label>

          <label htmlFor="email" tw="block w-full pb-5 flex flex-col">
            <span tw="text-gray-700">بريدك الالكتروني</span>
            <span tw="text-gray-600 text-xs">
              لضمان وصول الرسالة استخدم بريد gmail
            </span>
            <div tw="relative">
              <input
                id="email"
                type="email"
                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                required
                tw="form-input mt-1 block w-full px-10"
                dir="ltr"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
              <span tw="absolute inset-0 right-auto flex items-center pl-2 text-black">
                <svg fill="#e2e8f0" tw="w-6 h-6" viewBox="0 0 512 512">
                  <path d="M339.392 258.624L512 367.744V144.896zM0 144.896v222.848l172.608-109.12zM480 80H32C16.032 80 3.36 91.904.96 107.232L256 275.264l255.04-168.032C508.64 91.904 495.968 80 480 80zM310.08 277.952l-45.28 29.824a15.983 15.983 0 01-8.8 2.624c-3.072 0-6.112-.864-8.8-2.624l-45.28-29.856L1.024 404.992C3.488 420.192 16.096 432 32 432h448c15.904 0 28.512-11.808 30.976-27.008L310.08 277.952z" />
                </svg>
              </span>
            </div>
          </label>

          <SubmitButton />
        </FormComponent>
      </Box>
    </>
  )
}

export { FormComponent }
export default Form
